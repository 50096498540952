<template>
    <div>
        <div class="container-geral">
            <div class="filters">
                <h1>Filtros:</h1>
                <div class="filtro">
                    <select
                        :name="statusFiltrado"
                        id="statusFiltrado"
                        class="filtro-select"
                        @change="alteraStatus($event)"
                    >
                        <option value="all" selected>Todos os Status</option>
                        <option :value="statu" v-for="statu in status" :key="statu">{{ statu }}</option>
                        <!-- <option value="Vencido sem Aprovação">Vencido sem Aprovação</option>
                        <option value="Reprovado">Reprovado</option>
                        <option value="Aprovado">Aprovado</option> -->
                    </select>
                </div>
                <div class="filtro">
                    <select :name="dataFiltrada" id="dataFiltrada" class="filtro-select" @change="alteraData($event)">
                        <option value="all" selected>Todas as Datas</option>
                        <option :value="validade" v-for="validade in validades" :key="validade">
                            {{ convertDate(validade) }}
                        </option>
                    </select>
                </div>
                <div class="filtro">
                    <select
                        :name="categoriaFiltrada"
                        id="categoriaFiltrada"
                        class="filtro-select"
                        @change="alteraCategoria($event)"
                    >
                        <option value="all" selected>Todas Categorias</option>
                        <option :value="categoria" v-for="categoria in categorias" :key="categoria">
                            {{ categoria }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="empty-filter" v-if="emptyFilter">Este filtro está vazio!</div>
            <template v-for="(tarefa, keyTarefa) in tarefas" :key="keyTarefa">
                <div class="corredores">
                    <h2 class="corredores-title">
                        {{ keyTarefa }}
                    </h2>
                    <span class="numbers">
                        {{ Object.keys(tarefa).length }}
                    </span>
                </div>
                <div class="container-tarefa">
                    <div class="produtos">
                        <div v-for="(produto, keyProduto) in tarefa" :key="keyProduto" class="produto1">
                            <div class="container-img">
                                <img
                                    :src="
                                        'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                                        produto.codigo_de_barras +
                                        '.webp'
                                    "
                                    @error="replaceByDefault"
                                    :alt="produto.descricao"
                                />
                            </div>
                            <div class="nome-validade">
                                <h2 class="tarefa-descricao">
                                    {{ produto.descricao }}
                                </h2>
                                <h3 class="validade-tarefa">Código: {{ produto.codigo_de_barras }}</h3>
                                <h3 class="validade-tarefa">
                                    Validade: {{ convertDate(produto.data_validade) }} - QTD: {{ produto.quantidade }}
                                </h3>
                                <h3 class="validade-tarefa">
                                    Alto giro: {{ produto.tipo_status == null ? 'Não' : 'Sim' }}
                                </h3>
                                <h3 class="validade-tarefa">Preço atual: {{ showActualPrice(produto.preco_atual) }}</h3>
                                <h3 class="validade-tarefa">
                                    Preço solicitado: {{ showActualPrice(produto.preco_solicitado) }}
                                </h3>
                                <h3 v-if="produto.status == 'Aprovado'" class="validade-tarefa">
                                    Preço aprovado: {{ showActualPrice(produto.preco_comercial) }}
                                </h3>
                                <h3 class="validade-tarefa">
                                    Solicitado em: {{ convertDate(produto.data_solicitacao) }} -
                                    {{ formatHour(produto.hora_solicitacao) }}
                                </h3>
                                <h3 class="validade-tarefa produto-pendente" v-if="produto.status == 'Pendente'">
                                    Status: {{ produto.status }}
                                </h3>
                                <h3
                                    class="validade-tarefa produto-recusado"
                                    v-if="
                                        produto.status == 'Reprovado' ||
                                        produto.status == 'Reprovado Sem Estoque' ||
                                        produto.status == 'Vencido sem Aprovação'
                                    "
                                >
                                    Status: {{ produto.status }}
                                </h3>
                                <h3 class="validade-tarefa produto-aprovado" v-if="produto.status == 'Aprovado'">
                                    Status: {{ produto.status }}
                                </h3>
                                <h3 class="validade-tarefa produto-aprovado" v-if="produto.status == 'Aprovado'">
                                    Aprovado em: {{ convertHour(produto.hora_atualizacao) }}
                                </h3>
                                <h3 class="validade-tarefa produto-aprovado" v-if="produto.status == 'Aprovado'">
                                    Liberado para impressão em:
                                    {{ showPrint(produto.hora_atualizacao) }}
                                </h3>
                                <h3
                                    class="validade-tarefa produto-aprovado"
                                    v-if="produto.status == 'Aprovado' && produto.valido_ate != null"
                                >
                                    Valido até: {{ convertDate(produto.valido_ate) }}
                                </h3>
                            </div>
                            <div
                                v-if="produto.status == 'Pendente'"
                                class="exclui-pedido"
                                @click.prevent="warning(produto)"
                            >
                                <img src="../../assets/img/close-white.svg" alt="botão para editar" />
                            </div>
                            <div
                                v-if="produto.status == 'Reprovado' && produto.justificativa != null"
                                class="exclui-pedido"
                                @click.prevent="displayJustify(produto.justificativa)"
                            >
                                <img src="../../assets/img/edit-white.svg" alt="botão para editar" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <router-link class="btn-add-container" :to="{ name: 'pedidos.preco' }">
                <img src="../../assets/img/btn-add.svg" alt="botão para pedir preço especial" />
            </router-link>
        </div>

        <transition name="fade">
            <div v-if="showJustify" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        Justifique a recusa do pedido:
                        <div class="close-cancel" @click="(showJustify = false), (justify = '')">
                            <img src="../../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <div class="container-text">
                        <textarea
                            class="text-justify"
                            maxlength="100"
                            name="justify"
                            v-model="justify"
                            id="justify"
                            cols="50"
                            rows="7"
                            disabled
                        ></textarea>
                    </div>
                    <div class="cancel-btn">
                        <button @click="(showJustify = false), (justify = '')" type="button">Ok</button>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="isWarning" class="popUp2">
                <div class="tela-mensagem2">
                    <div class="cancel-msg2">
                        {{ mensagemAviso.top }}
                        <div class="close-cancel2" @click.prevent="isWarning = false">
                            <img src="../../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <h3>
                        {{ mensagemAviso.body1 }} <br />
                        {{ mensagemAviso.body2 }} <br />
                        {{ mensagemAviso.body3 }} <br />
                        {{ mensagemAviso.body4 }} <br />
                        {{ mensagemAviso.body6 }} <br />
                        {{ mensagemAviso.body7 }} <br />
                    </h3>
                    <div class="cancel-btn2">
                        <button @click="isWarning = false" type="button">
                            {{ mensagemAviso.cancel }}
                        </button>
                        <button @click="removePedido(targetErase)" type="button">
                            {{ mensagemAviso.accept }}
                        </button>
                    </div>
                </div>
            </div>
        </transition>

        <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
        <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import img from '../../assets/img/broken-image.svg';
import HTTP from '../../http/http.js';
import MsgSucess from '../../components/MsgSucess';
import MsgError from '../../components/MsgError';
import moment from 'moment';

export default {
    data() {
        return {
            tarefas: null,
            tipoTarefa: '',
            mensagemAviso: {
                top: '',
                body1: '',
                body2: '',
                body3: '',
                body4: '',
                body6: '',
                body7: '',
                cancel: '',
                accept: '',
            },
            isWarning: false,
            targetErase: {
                codigo_de_barras: '',
                data_solicitacao: '',
                data_validade: '',
                descricao: '',
                loja: '',
                preco_solicitado: '',
                quantidade: '',
                status: '',
            },
            showSucess: false,
            showAlert: false,
            msgS: '',
            msgE: '',
            statusFiltrado: 'all',
            dataFiltrada: 'all',
            categoriaFiltrada: 'all',
            todasTarefas: null,
            emptyFilter: false,
            showJustify: false,
            justify: '',
            validades: [],
            dataEscolhida: null,
            statusEscolhido: null,
            categoriaEscolhida: null,
            categorias: [],
            status: [],
        };
    },
    components: {
        MsgSucess,
        MsgError,
    },
    computed: {
        getPrecos() {
            return this.$store.getters.getPrecos;
        },
        getPrecosCategoria() {
            return this.$store.getters.getPrecosCategoria;
        },
    },
    created() {
        this.tarefas = this.getPrecosCategoria;
        this.todasTarefas = this.getPrecosCategoria;
        this.attStores();
    },
    methods: {
        convertDate(date) {
            if (date != '') {
                let dateSplit = date.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        convertHour(value) {
            if (value) {
                let dateSplit = value.split(' ');
                let date = this.convertDate(dateSplit[0]);
                let hourSplit = dateSplit[1].split(':');
                return date + ' - ' + hourSplit[0] + ':' + hourSplit[1];
            } else {
                return 'Sem registro.';
            }
        },

        showPrint(hour) {
            if (hour != null) {
                let hourSplit = hour.split(' ');
                let firstHour = hourSplit[1].split(':');
                let finalHour = firstHour[0];
                if (finalHour < 13) {
                    let data = moment(hourSplit[0]);
                    return data.add(1, 'd').format('DD/MM/YYYY');
                } else {
                    let data = moment(hourSplit[0]);
                    return data.add(2, 'd').format('DD/MM/YYYY');
                }
            } else {
                return 'Sem registro';
            }
        },

        attStores() {
            this.storeCategorias();
            this.storeValidades();
            this.storeStatus();
        },

        storeStatus() {
            let statusRaw = [];
            Object.keys(this.tarefas).forEach((element) => {
                this.tarefas[element].forEach((value) => {
                    statusRaw.push(value.status);
                    let containerStatus = new Set(statusRaw.sort());
                    this.status = containerStatus;
                });
            });
        },

        storeValidades() {
            let validadesRaw = [];
            Object.keys(this.tarefas).forEach((element) => {
                this.tarefas[element].forEach((value) => {
                    validadesRaw.push(value.data_solicitacao);
                    let containerValidades = new Set(validadesRaw.sort());
                    this.validades = containerValidades;
                });
            });
        },

        storeCategorias() {
            let categoriasRaw = [];
            Object.keys(this.tarefas).forEach((element) => {
                this.tarefas[element].forEach((value) => {
                    categoriasRaw.push(value.categoria_2);
                    let containerCategorias = new Set(categoriasRaw.sort());
                    this.categorias = containerCategorias;
                });
            });
        },

        formatHour(value) {
            if (value) {
                let hourSplit = value.split(':');
                return hourSplit[0] + ':' + hourSplit[1];
            } else {
                return 'Sem registro';
            }
        },

        showActualPrice(value) {
            if (value != null) {
                return 'R$ ' + parseFloat(value).toFixed(2);
            } else {
                return 'sem registro';
            }
        },

        displayJustify(value) {
            this.justify = value;
            this.showJustify = true;
        },
        replaceByDefault(e) {
            e.target.src = img;
        },
        alteraStatus(event) {
            this.statusEscolhido = event.target.value;

            if (this.statusEscolhido == 'all') {
                if (this.dataEscolhida || this.categoriaEscolhida) {
                    this.statusEscolhido = null;
                    this.filtraTarefa();
                    this.attStores();
                    return;
                }

                this.statusEscolhido = null;
                this.tarefas = this.todasTarefas;
                this.checkFilter(this.tarefas);
                this.attStores();
            } else {
                this.filtraTarefa();
                this.attStores();
            }
        },
        alteraData(event) {
            this.dataEscolhida = event.target.value;

            if (this.dataEscolhida == 'all') {
                if (this.statusEscolhido || this.categoriaEscolhida) {
                    this.dataEscolhida = null;
                    this.filtraTarefa();
                    this.attStores();
                    return;
                }

                this.dataEscolhida = null;
                this.tarefas = this.todasTarefas;
                this.checkFilter(this.tarefas);
                this.attStores();
            } else {
                this.filtraTarefa();
                this.attStores();
            }
        },
        alteraCategoria(event) {
            this.categoriaEscolhida = event.target.value;

            if (this.categoriaEscolhida == 'all') {
                if (this.statusEscolhido || this.dataEscolhida) {
                    this.categoriaEscolhida = null;
                    this.filtraTarefa();
                    this.attStores();
                    return;
                }

                this.categoriaEscolhida = null;
                this.tarefas = this.todasTarefas;
                this.checkFilter(this.tarefas);
                this.attStores();
            } else {
                this.filtraTarefa();
                this.attStores();
            }
        },
        filtraTarefa() {
            const todosPedidos = this.getPrecosCategoria;

            let resultado = {};
            Object.keys(todosPedidos).forEach((element) => {
                let value = todosPedidos[element].filter((element) => {
                    if (this.dataEscolhida && this.statusEscolhido && this.categoriaEscolhida) {
                        return (
                            element.data_solicitacao == this.dataEscolhida &&
                            element.status == this.statusEscolhido &&
                            element.categoria_2 == this.categoriaEscolhida
                        );
                    } else if (this.dataEscolhida && this.statusEscolhido) {
                        return element.data_solicitacao == this.dataEscolhida && element.status == this.statusEscolhido;
                    } else if (this.dataEscolhida && this.categoriaEscolhida) {
                        return (
                            element.data_solicitacao == this.dataEscolhida &&
                            element.categoria_2 == this.categoriaEscolhida
                        );
                    } else if (this.statusEscolhido && this.categoriaEscolhida) {
                        return element.status == this.statusEscolhido && element.categoria_2 == this.categoriaEscolhida;
                    } else if (this.dataEscolhida) {
                        return element.data_solicitacao == this.dataEscolhida;
                    } else if (this.statusEscolhido) {
                        return element.status == this.statusEscolhido;
                    } else if (this.categoriaEscolhida) {
                        return element.categoria_2 == this.categoriaEscolhida;
                    }
                });
                if (value != '') {
                    resultado[element] = value;
                }
            });

            this.tarefas = resultado;
            this.checkFilter(this.tarefas);
        },
        checkFilter(filter) {
            if (Object.keys(filter).length == '') {
                this.emptyFilter = true;
            } else {
                this.emptyFilter = false;
            }
        },
        warning(target) {
            this.isWarning = true;

            let mensagemAviso = this.mensagemAviso;

            mensagemAviso.top = 'Deseja realmente cancelar esse pedido?';
            mensagemAviso.body1 = `Código: ${target.codigo_de_barras} `;
            mensagemAviso.body2 = `Produto: ${target.descricao}`;
            mensagemAviso.body3 = `Validade: ${this.convertDate(target.data_validade)}`;
            mensagemAviso.body4 = `Quantidade: ${target.quantidade}`;
            mensagemAviso.body6 = `Preço Solicitado: R$ ${target.preco_solicitado.toFixed(2)}`;
            mensagemAviso.body7 = `Status: ${target.status}`;
            mensagemAviso.cancel = 'Voltar';
            mensagemAviso.accept = 'Cancelar pedido';

            this.targetErase = target;
        },
        removePedido(target) {
            let codigo = target.codigo_de_barras;
            let validade = target.data_validade;

            let todosPedidos = this.tarefas;

            let dataVuex = {
                codigo: target.codigo_de_barras,
                validade: target.data_validade,
            };

            const itemToBeRemoved = { codigo, validade };

            Object.keys(todosPedidos).map((element) => {
                for (let i = 0; i < todosPedidos[element].length; i++) {
                    if (
                        todosPedidos[element][i].codigo_de_barras == itemToBeRemoved.codigo &&
                        todosPedidos[element][i].data_validade == itemToBeRemoved.validade
                    ) {
                        todosPedidos[element].splice(i, 1);
                        this.$store.dispatch('deletePedido', dataVuex);

                        if (todosPedidos[element] == '') {
                            delete todosPedidos[element];
                        }
                        break;
                    }
                }
            });
            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.delete(`${loja}/admin/precos`, { data: target })
                    .then(() => {
                        this.isWarning = false;
                        this.showSucess = true;
                        this.msgS = 'Pedido excluido com sucesso!';

                        setTimeout(() => {
                            this.showSucess = false;
                        }, 1500);
                    })
                    .catch(() => {
                        this.showAlert = true;
                        this.msgE = 'Erro ao excluir o produto, tente novamente.';

                        setTimeout(() => {
                            this.showAlert = false;
                        }, 1500);
                    });
            } else {
                let precosSalvos = JSON.parse(localStorage.getItem('precos'));

                let dataToLS = null;

                if (precosSalvos) {
                    precosSalvos.push(target);

                    dataToLS = JSON.stringify(precosSalvos);
                } else {
                    dataToLS = JSON.stringify([target]);
                }

                this.msgS = 'Seu pedido será excluído ao sincronizar!';
                this.showSucess = true;

                localStorage.setItem('precosDelete', dataToLS);

                this.$store.dispatch('plusNumeroSync');

                setTimeout(() => {
                    this.showSucess = false;
                    this.isWarning = false;
                }, 1500);
            }
            target = '';
        },
    },
};
</script>

<style scoped>
.container-geral {
    margin: 86px 15px 97px;
}

.filters {
    border-radius: 10px;
    padding: 15px 10px;
    background-color: var(--verde);
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filters h1 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
}

.filtro {
    display: flex;
    place-items: center;
    gap: 10px;
}

.filtro-select {
    width: 200px;
    padding: 8px;
    letter-spacing: 0.5px;
    background-color: var(--azul-escuro);
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    outline-color: var(--azul-escuro);
}

.empty-filter {
    padding: 20px;
    color: var(--vermelho);
    font-size: 1.5rem;
    font-weight: 600;
}

.label-filter {
    font-size: 1.1rem;
    color: #fff;
}

.container-tarefa {
    margin: 15px 10px;
    border-radius: 5px;
    /* box-shadow: 0 0 5px var(--cinza-escuro); */
}

.corredores {
    padding: 10px 12px;
    margin: 20px 3% 0px;
    border-radius: 10px;
    background-color: var(--azul-escuro);
    border-bottom: 1px solid var(--azul-escuro);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 0;
    box-shadow: 4px 6px 7px #858585;
}

.container-tarefa {
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 0px 5%;
    border: 2px solid var(--azul-escuro);
    border-radius: 10px;
    margin-top: -10px;
    background-color: #fff;
    padding: 20px 10px;
}

.corredores-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    max-width: 25ch;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.numbers {
    font-size: 12px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ef6969;
    color: #ef6969;
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
}

.produto1 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 30px 10px;
    /* background-color: var(--verde); */
    margin-bottom: 20px;
    border: 2px solid var(--azul-escuro);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.exclui-pedido {
    position: absolute;
    right: 10px;
    bottom: 7px;
    padding: 8px;
    border: 1px solid var(--verde);
    display: flex;
    border-radius: 50%;
    background-color: var(--verde);
    transition: all 1s;
}

.exclui-pedido img {
    width: 18px;
}

.exclui-pedido:hover {
    animation: is-rotating 1s infinite;
    box-shadow: none;
}

.container-img {
    display: flex;
    place-items: center;
}

.container-img img {
    width: 70px;
    height: 70px;
}

.nome-validade {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.nome-validade h2 {
    font-size: 13px;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 20ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #484848;
    font-weight: 700;
}

.validade-tarefa {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #484848;
}

.produto-pendente {
    color: rgb(229 206 0);
}

.produto-recusado {
    color: #ef6969;
}

.produto-aprovado {
    color: var(--verde);
}

h2.produto-alerta {
    color: #ef6969;
    font-weight: bold;
}

.title-retirada {
    color: #fff;
}

.validade-retirada {
    color: #ef6969;
}

.btn-add-container {
    width: 56px;
    height: 56px;
    display: flex;
    place-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--verde);
    position: fixed;
    bottom: 10%;
    left: 76%;
    box-shadow: 0 0 5px #000;
}

.btn-add-container img {
    width: 24px;
    height: 24px;
}

@media (max-width: 319px) {
    .corredores-title {
        font-size: 14px;
        max-width: 24ch;
    }

    .nome-validade h2 {
        max-width: 17ch;
    }
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

/* Tela Warning de cancelamento */

.popUp2 {
    position: fixed;
    background-color: rgba(0, 0, 0, 65%);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem2 {
    position: absolute;
    left: 0;
    top: 20%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem2 h3 {
    padding: 5px 15px;
    font-size: 17px;
}

.cancel-msg2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel2 {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel2:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn2 {
    margin-top: 15px;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn2 button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn2 button:hover {
    position: relative;
    top: 5px;
    background-color: var(--verde);
    color: #fff;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 65%);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 2%;
    top: 10%;
    right: 2%;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 17px;
}

.tela-mensagem img {
    width: 200px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 17px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel img {
    width: 24px;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.container-text {
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    justify-content: center;
}

.text-justify {
    border-radius: 5px;
    outline-color: var(--verde);
    resize: none;
    height: fit-content;
}

.cancel-btn {
    margin-top: 15px;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 2px;
    background-color: var(--verde);
    color: #fff;
}

@media (min-width: 768px) {
    .btn-add-container {
        left: 92%;
    }
}
</style>
